import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";
import "../styles/main.sass";
import NavBar from "../components/navBar";
import Logo from "../images/logo-ferro.svg";
import Image1 from "../images/offline-image.png";
import Image2 from "../images/transistor-man-with-camera.png";
import paoloFerro from "../images/paolo-ferro.jpeg";
import "antd/dist/antd.css";

import SezCat from "../data/sezioni-categorie";

const IndexPage = () => {
  return (
    <>
      <NavBar></NavBar>
      <div style={{ position: "relative" }}>
        <StaticImage
          className="cover"
          alt="bambina al piano"
          src="../images/cover.jpg"
        />
        <div
          style={{ backgroundColor: "black", opacity: 0.5 }}
          className="cover-filter"
        ></div>
        <div className="cover-filter">
          <h1 style={{ textAlign: "center", fontSize: 35, fontWeight: 600 }}>
            Concorso musicale <br />
            <span>Paolo Ferro</span>
          </h1>
          <div style={{ fontSize: 20 }}>I.C. Elio Vittorini di Scicli</div>
          <img
            width="80"
            src={Logo}
            style={{ position: "absolute", bottom: 50 }}
          />
        </div>
      </div>
      <section id="section-2">
        <div className="section-2-hero section-2-w">
          Edizione
          <br /> 2024
        </div>
        <div className="section-2-w">
          <div className="header-2">Iscrizioni</div>
          <p>Dal 1/03/2024 al 10/05/2024</p>
          <div className="header-2">Date delle prove</div>
          <p>Dal 21 al 23 maggio</p>
          <div className="header-2">Luogo delle Prove</div>
          <p>
            I.C. Elio Vittorini, Scicli <br />
            Teatro Italia, Scicli
          </p>
        </div>
      </section>
      <section id="sezione-3-w">
        <h2>Scegli come vuoi partecipare</h2>
        <div className="cards-w">
          <div style={{ backgroundColor: "#f0fcff" }} className="card">
            <div className="card-title">In presenza</div>
            <p>
              Iscriviti al concorso attraverso
              <br /> il{" "}
              <a
                href="/iscrizione-concorso-in-presenza"
                style={{ borderBottom: "2px solid #00A9D4" }}
              >
                Modulo d'iscrizione
              </a>
            </p>
            <a href="/regolamento-concorso-in-presenza">
              <button style={{ backgroundColor: "#00A9D4" }}>
                Regolamento
              </button>
            </a>

            <img width={200} src={Image1} />
          </div>
          <div style={{ backgroundColor: "#fff5f0" }} className="card">
            <div className="card-title">Online</div>
            <p>
              Registra un video ed invialo attraverso
              <br /> il{" "}
              <a
                href="/iscrizione-concorso-online"
                style={{ borderBottom: "2px solid #FF7D3B" }}
              >
                Modulo d'iscrizione
              </a>
            </p>
            <a href="/regolamento-concorso-online">
              <button style={{ backgroundColor: "#FF7D3B" }}>
                Regolamento
              </button>
            </a>

            <img width={200} src={Image2} />
          </div>
        </div>
        <h3>I numeri delle edizioni passate</h3>
        <div className="numbers-w">
          <div className="numbers-card">
            <div>1000+</div>
            <p>Iscritti online</p>
          </div>
          <div className="numbers-card">
            <div>2000+</div>
            <p>Prove giudicate</p>
          </div>
          <div className="numbers-card">
            <div>87</div>
            <p>Premi assegnati</p>
          </div>
        </div>
      </section>

      <section id="section-4-w">
        <h2 style={{ fontSize: 30, fontWeight: 700 }}>Sezioni e categorie</h2>
        {SezCat.map((item) => {
          return (
            <div key={item.nome}>
              <div className="section">
                <b>{item.nome} </b>
                {item.descrizione}
              </div>
              {item.categorie.map((i, index) => {
                return (
                  <p key={i.nome + index}>
                    <b>{i.nome} </b>
                    {i.descrizione}
                  </p>
                );
              })}
            </div>
          );
        })}
      </section>
      <section id="paolo-ferro">
        <img width="200px" src={paoloFerro} alt="foto Paolo Ferro" />
        <p>
          A Paolo Ferro, ragazzo brillante, amante della musica, scomparso
          prematuramente a causa di un incidente stradale
        </p>
      </section>
      <section id="section-5-w">
        <h2>Contatti</h2>
        <div
          style={{
            paddingBottom: 150,
            maxWidth: 500,
            margin: "auto",
            width: "90%",
          }}
        >
          <b>Prof. Diego Cristofaro</b> Direttore Artistico e Docente di
          Pianoforte dell’IC “E. Vittorini” di Scicli, tel. 3381076760
          <p />
          <p></p>
          <b>Prof.ssa Mariangela Bruno </b>
          Docente di Chitarra dell’IC “E. Vittorini” di Scicli, tel.3295983264
          <p style={{ textAlign: "center" }}>
            <a href="https://www.facebook.com/profile.php?id=100029187172887">
              Seguici su Facebook
            </a>
          </p>
        </div>
      </section>
    </>
  );
};

export const Head = () => (
  <>
    <title>Concorso Musicale Paolo Ferro</title>
    <meta
      name="description"
      content="Iscriviti al concorso online musicale Paolo Ferro"
    />

    <meta property="og:url" content="https://concorsopaoloferro.edu.it/" />
    <meta property="og:type" content="website" />
    <meta property="og:title" content="Concorso Paolo Ferro" />
    <meta
      property="og:description"
      content="Iscriviti al concorso online musicale Paolo Ferro"
    />
    <meta
      property="og:image"
      content="https://concorsopaoloferro.edu.it/cover.jpg"
    />

    <meta name="twitter:card" content="summary_large_image" />
    <meta property="twitter:domain" content="concorsopaoloferro.edu.it" />
    <meta property="twitter:url" content="https://concorsopaoloferro.edu.it/" />
    <meta name="twitter:title" content="Concorso Paolo Ferro" />
    <meta
      name="twitter:description"
      content="Iscriviti al concorso online musicale Paolo Ferro"
    />
    <meta
      name="twitter:image"
      content="https://concorsopaoloferro.edu.it/cover.jpg"
    />
    <link rel="canonical" href="https://concorsopaoloferro.edu.it" />
  </>
);

export default IndexPage;
